.hamburger {
    display: none;
}

.user_btn {
    display: none;
}

.sp_btns {
    display: none;
}

@media screen and (max-width: 768px) {
    .sp_btns {
        display: flex;
    }

    .css-1qzqbde-MuiButtonBase-root-MuiIconButton-root {
        margin-right: 0;
    }

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
        font-size: 14px;
    }

    .hamburger {
        display: block;
    }

    .user_btn {
        display: block;
    }

    .userbox {
        display: none;
    }
}

.hamburger_icon,
.user_icon {
    color: #fff;
}

.css-19r6kue-MuiContainer-root {
    max-width: 100%;

}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: #4682b4;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

.css-r6ewbb-MuiToolbar-root {
    min-height: 50px;
}

.logo {
    width: 200px;
    height: 35px;
}

.user {
    padding-right: 20px;
    font-size: 14px;
}

.usericon {
    width: 25px;
    height: 25px;
    color: #fff;
}