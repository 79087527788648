.contentList body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contentList code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contentList * {
  box-sizing: border-box;
}

.contentList html {
  font-family: sans-serif;
  font-size: 14px;
}

.contentList table,
.contentList.divTable {
  border: 1px solid lightgray;
  width: fit-content;
}

.contentList.tr {
  display: flex;
}

.contentList tr,
.contentList.tr {
  width: fit-content;
  height: 30px;
}

.contentList th,
.contentList.th,
.contentList td,
.contentList.td {
  box-shadow: inset 0 0 0 1px lightgray;
  padding: 0.25rem;
}

.contentList th,
.contentList.th {
  padding: 2px 4px;
  position: relative;
  font-weight: bold;
  text-align: center;
  height: 30px;
}

.contentList td,
.contentList .td {
  height: 30px;
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.noResize {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 5px;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: #3071a9;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
