
.tablebox{
  overflow-y: auto;
  overflow-x: scroll;
  width: auto;
  /* max-height: 80vh; */
  /* padding-bottom: 10px; */
}
/* スクロールバーのベース */
.tablebox::-webkit-scrollbar {
  width: 10px; /* 垂直方向のスクロールバーの幅 */
  height: 10px; /* 水平方向のスクロールバーの幅 */
}
.tablebox::-webkit-scrollbar-track {
  background-color: #fff;
}
.tablebox::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 100px;
}

.searchListZero9 {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.searchListZero9 code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.searchListZero9 * {
  box-sizing: border-box;
}

.searchListZero9 html {
  font-family: sans-serif;
  font-size: 14px;
}

.searchListZero9 table,
.searchListZero9.divTable {
  border: 1px solid lightgray;
  width: fit-content;
  border-spacing: 0;
  width:max-content;
  border: none;
  /* セルの線を重ねる */
  border-collapse:  collapse;
}

.searchListZero9.tr {
  display: flex;
}

.searchListZero9 tr,
.searchListZero9.tr {
  width: fit-content;
  height: 40px;
}

.searchListZero9 th,
.searchListZero9.th,
.searchListZero9 td,
.searchListZero9.td {
  text-align: center;
  box-shadow: inset 0 0 0 1px lightgray;
  /* padding: 0.25rem; */
  width: auto;
  padding: 5px 10px;
}

.searchListZero9 th,
.searchListZero9.th {
  padding: 2px 4px;
  position: relative;
  font-weight: bold;
  text-align: center;
  height: 40px;
  font-size: 13px;
}

.searchListZero9 td,
.searchListZero9 .td {
  font-size: 14px;
  height: 40px;
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

.searchListZero9 th {
  position: sticky;
  top: 0;

  z-index: 1; /* 他のコンテンツよりも上に表示されるようにするためのz-index */



  background: linear-gradient(#51bdbd, #51bdbd); /* 背景色のグラデーションを指定する#6db2bf */

}
.searchListZero9 thead {
  position: sticky;
  top: 0;

  z-index: 1; /* 他のコンテンツよりも上に表示されるようにするためのz-index */



  background: linear-gradient(#51bdbd, #51bdbd); /* 背景色のグラデーションを指定する#6db2bf */

}
.searchListZero9 tfoot {
  position: sticky;
  bottom: 0;

  z-index: 1; /* 他のコンテンツよりも上に表示されるようにするためのz-index */


  background: linear-gradient(#fefefe, #efefef); /* 背景色のグラデーションを指定する */
  
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
