
.tablebox{
  overflow-y: auto;
  overflow-x: scroll;
  width: auto;
  /* max-height: 80vh; */
  /* padding-bottom: 10px; */
}
/* スクロールバーのベース */
.tablebox::-webkit-scrollbar {
  width: 10px; /* 垂直方向のスクロールバーの幅 */
  height: 10px; /* 水平方向のスクロールバーの幅 */
}
.tablebox::-webkit-scrollbar-track {
  background-color: #fff;
}
.tablebox::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 100px;
}

.vendor_list {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: YakuHanJP, "Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vendor_list code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vendor_list * {
  box-sizing: border-box;
}

.vendor_list html {
  font-family: sans-serif;
  font-size: 14px;
}

.vendor_list table,
.vendor_list.divTable {
  border: 1px solid lightgray;
  width: fit-content;
  border-spacing: 0;
  width:max-content;
  border: none;
  /* セルの線を重ねる */
  border-collapse:  collapse;
}

.vendor_list.tr {
  display: flex;
}

.vendor_list tr,
.vendor_list.tr {
  width: fit-content;
  height: 40px;
}

.vendor_list th,
.vendor_list.th,
.vendor_list td,
.vendor_list.td {
  text-align: left;
  box-shadow: inset 0 0 0 1px lightgray;
  /* padding: 0.25rem; */
  width: auto;
  padding: 5px 10px;
  border: 1px;
}

.vendor_list th,
.vendor_list.th {
  padding: 10px;
  position: relative;
  font-weight: bold;
  text-align: left !important;
  height: 40px;
  font-size: 13px;
  
}

.vendor_list td,
.vendor_list .td {
  font-size: 14px;
  height: 40px;
  text-align: left !important;
}



.vendor_list th {
  position: sticky;
  top: 0;

  z-index: 1; /* 他のコンテンツよりも上に表示されるようにするためのz-index */



  background: linear-gradient(#ffffff, #e9e9eb); /* 背景色のグラデーションを指定する#6db2bf */

}
.vendor_list thead {
  position: sticky;
  top: 0;

  z-index: 1; /* 他のコンテンツよりも上に表示されるようにするためのz-index */



  background: linear-gradient(#51bdbd, #51bdbd); /* 背景色のグラデーションを指定する#6db2bf */

}

.table-row:hover {
  background-color: #ffecb9;
}
.table-row2 {
  background-color: #f5f5f5;
}
.table-row2:hover {
  background-color: #ffecb9;
}


.btn {
  margin-top: 10px;
  display: inline-block;
  padding: 0.2em 1em;
  border: 2px solid #2e7d32;
  border-radius: 5px;
  background-color: #2e7d32;
  color: white;
  text-decoration: none;
  font-size: 1em;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.btn:hover {
  background-color: #316a34;
  border-color: #316a34;
}


@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
